import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Serverless Pros & Cons",
  "description": "Serverless isn't for everyone every time. Learn how to make the right decision",
  "image": "./img/serverless-pros-cons.png"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "serverless-pros--cons--when-should-you-go-serverless"
    }}>{`Serverless Pros & Cons – when should you go serverless?`}</h1>
    <p><img parentName="p" {...{
        "src": "/af06602f80a6a7f72b421e63acf2ac8e/serverless-pros-cons.svg",
        "alt": null
      }}></img></p>
    <p>{`Okay you've heard of serverless, tried it out, and you think it's neat. But should you `}<em parentName="p">{`really`}</em>{` go serverless for your next project?`}</p>
    <p>{`Yes!`}</p>
    <p>{`Most of the time ...`}</p>
    <p><video parentName="p" {...{
        "style": {
          "margin": "auto auto",
          "display": "block",
          "maxWidth": "80%"
        },
        "autoPlay": true,
        "loop": true,
        "muted": true,
        "playsInline": true,
        "loading": "lazy"
      }}>{`
            `}<source parentName="video" {...{
          "src": "https://media2.giphy.com/media/l0CLThEZp4OtNNsli/giphy-loop.mp4?cid=4ac046a2kk3f1m8nbm8u8mw8kh8bt9dbetr715qw3xfpq3ai&rid=giphy-loop.mp4&ct=g",
          "type": "video/mp4"
        }}></source>{`
        `}</video></p>
    <p>{`Serverless is a great option for most projects most of the time. You save configuration and maintenance time, gain flexibility, and in extreme cases spend more $$ per request than building your own servers.`}</p>
    <p>{`Large apps can reach the cost curve limits of serverless. Bank of America, for example, `}<a parentName="p" {...{
        "href": "https://www.businessinsider.com/bank-of-americas-350-million-internal-cloud-bet-striking-payoff-2019-10"
      }}>{`announced $2B in savings`}</a>{` from building their own data centers.`}</p>
    <p>{`You won't hit those issues. And if you do, I hope there's a business model to back it up and you can afford DevOps professionals. 😛`}</p>
    <p>{`Large orgs tend to provide a cloud or serverless-like environment internally. If you have access to that, use it.`}</p>
    <h2 {...{
      "id": "serverless-is-an-ecosystem"
    }}>{`Serverless is an ecosystem`}</h2>
    <p>{`When I say serverless, I don't mean just throwing up code on a `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Function_as_a_service"
      }}>{`function-as-a-service`}</a>{` platform like AWS Lambda. I'm talking about the whole ecosystem.`}</p>
    <p>{`The core idea is this:`}</p>
    <ol>
      <li parentName="ol">{`Backend does as little as possible`}</li>
      <li parentName="ol">{`Clients tie everything together`}</li>
      <li parentName="ol">{`Static files come from fast `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Content_delivery_network"
        }}>{`content delivery networks`}</a></li>
      <li parentName="ol">{`Database handles data consistency`}</li>
      <li parentName="ol">{`As much work as possible happens at compile and deploy time`}</li>
    </ol>
    <p>{`Users' and developers' machines do the hard work.`}</p>
    <p>{`Is part of your app the same for every user? Package it up at deploy time. No need to bother the server `}<em parentName="p">{`or`}</em>{` the client with that work.`}</p>
    <p>{`Is part of your app specific to individual users? Let the client handle it. Every phone is a powerful computer these days.`}</p>
    <p>{`Got dynamic data that needs to synchronize across multiple sessions or users? Background processes that aren't tied to a specific session? Perfect fit for your server and database.`}</p>
    <p>{`We go in depth about this architecture in the chapter on `}<a parentName="p" {...{
        "href": "/serverless-architecture-principles"
      }}>{`Serverless Architecture Principles`}</a>{`.`}</p>
    <h2 {...{
      "id": "serverless-pros"
    }}>{`Serverless pros`}</h2>
    <p>{`The main benefit of serverless is that you don't deal with servers. They're somebody else's problem.`}</p>
    <h3 {...{
      "id": "you-save-time"
    }}>{`You save time`}</h3>
    <p>{`You focus on `}<em parentName="p">{`application`}</em>{` code. No more tedious maintenance tasks that aren't specific to your problem. `}</p>
    <p>{`Bye bye yak shaving. 👋`}</p>
    <p><video parentName="p" {...{
        "style": {
          "margin": "auto auto",
          "display": "block",
          "maxWidth": "80%"
        },
        "autoPlay": true,
        "loop": true,
        "muted": true,
        "playsInline": true,
        "loading": "lazy"
      }}>{`
            `}<source parentName="video" {...{
          "src": "https://media4.giphy.com/media/l4Epcn1A7RoWz0c9O/giphy-loop.mp4?cid=4ac046a2lit9lv6aw3syaxucviw7un4wr43nj3vqa2xpbscw&rid=giphy-loop.mp4&ct=g",
          "type": "video/mp4"
        }}></source>{`
        `}</video></p>
    <p><em parentName="p">{`"I need an API. That means I have to run a server. Which means I need Apache or Nginx to map HTTP requests to my app server. I need a computer to run all that. Which means I have to set up a whole operating system. Then I have to make sure everything runs at boot. And if a process goes down, it needs to restart. And ..."`}</em></p>
    <p>{`After all that work you get to build your application.`}</p>
    <p>{`With serverless `}<strong parentName="p">{`you save time otherwise spent managing servers.`}</strong>{` Whether that's you personally or a DevOps team in your organization. `}</p>
    <h3 {...{
      "id": "programming-productivity"
    }}>{`Programming productivity`}</h3>
    <p>{`You write backend code more productively.`}</p>
    <div id="lock" />
    <p>{`Smaller, more self-contained code, ideally a single function, brings clarity and focus. `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Unix_philosophy#Do_One_Thing_and_Do_It_Well"
      }}>{`Do one thing and do it well`}</a></p>
    <p>{`With increased focus, you get:`}</p>
    <ul>
      <li parentName="ul">{`easier testing`}</li>
      <li parentName="ul">{`quicker understanding`}</li>
      <li parentName="ul">{`shorter development cycles`}</li>
    </ul>
    <h3 {...{
      "id": "often-cheaper"
    }}>{`Often cheaper`}</h3>
    <p>{`Serverless can be cheaper to run. `}</p>
    <p>{`You save opportunity and employee cost `}<em parentName="p">{`and`}</em>{` you're not paying for servers you aren't using.`}</p>
    <p>{`As mentioned in the `}<a parentName="p" {...{
        "href": "/getting-started"
      }}>{`Getting Started`}</a>{` chapter: before serverless, you'd have to (over)provision a bunch of machines in case there's a traffic spike. That means you're paying for servers you aren't using.`}</p>
    <p>{`With serverless, you pay per execution and run time. Like pay-as-you-go pricing: Run code, pay for that run.`}</p>
    <p><strong parentName="p">{`When there's no traffic, there's no cost. 👌`}</strong></p>
    <h3 {...{
      "id": "scalability"
    }}>{`Scalability`}</h3>
    <p>{`Google likes to call serverless architectures `}<em parentName="p">{`‌from prototype to production to planet-scale`}</em>{`. You don't want to use serverless at planet scale though.`}</p>
    <p>{`But Google is right: Serverless scales. A lot.`}</p>
    <p>{`The details on `}<em parentName="p">{`why`}</em>{` serverless is scalable are tricky to get into. It has to do with how much work you can pack into a single physical machine ... but there `}<em parentName="p">{`is`}</em>{` a machine somewhere and you might run out of those with truly planet-scale work 🤔`}</p>
    <p>{`It comes down to this: You're running a `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Elasticity_(cloud_computing)"
      }}>{`hyper-elastic server`}</a>{` that adapts to changes in workload at millisecond precision. In theory this gives you perfect utilization.`}</p>
    <h2 {...{
      "id": "serverless-cons"
    }}>{`Serverless cons`}</h2>
    <p>{`As much as I think serverless is the next big thing in web development, it's not all fun and games out there. There `}<em parentName="p">{`are`}</em>{` disadvantages to using serverless.`}</p>
    <h3 {...{
      "id": "higher-latency-for-low-workloads"
    }}>{`Higher latency for low workloads`}</h3>
    <p>{`Performance comes in two flavors:`}</p>
    <ol>
      <li parentName="ol">{`Latency`}</li>
      <li parentName="ol">{`Speed or bandwidth`}</li>
    </ol>
    <p>{`Latency talks about how long it takes from making a request to getting a response. Speed talks about how long it takes to do work.`}</p>
    <p>{`Each `}<em parentName="p">{`execution`}</em>{` is fast because the code is small and servers are fast. A few milliseconds and you're done.`}</p>
    <p>{`But `}<em parentName="p">{`latency`}</em>{` can be high. You're hitting the server cold every time. That means each request waits for the computer to wake up.`}</p>
    <p>{`That's why providers keep servers live between requests. But only if requests come often enough.`}</p>
    <p><strong parentName="p">{`For low traffic applications with low latency demands, you might need a constantly provisioned server.`}</strong></p>
    <h3 {...{
      "id": "sometimes-costly"
    }}>{`Sometimes costly`}</h3>
    <p>{`As `}<a parentName="p" {...{
        "href": "https://www.businessinsider.com/bank-of-americas-350-million-internal-cloud-bet-striking-payoff-2019-10"
      }}>{`Bank of America found out`}</a>{` pay-as-you-go pricing gets expensive when used a lot.`}</p>
    <p>{`Serverless providers charge based on number of requests and resources used. You pay for every request and every millisecond of computation. Known as "compute".`}</p>
    <p>{`If you have a lot of requests or long runtimes, you can rack up the costs beyond what you'd pay with your own servers.`}</p>
    <p>{`For example: You wouldn't want to train a machine learning model on a serverless architecture. Learned that painful lesson with my first startup in 2010 and GoogleAppEngine. Flicked the On switch and the credit card melted 🔥`}</p>
    <p>{`Another bad case for serverless are high traffic applications. At millions of requests per second, you're better off on your own.`}</p>
    <p><strong parentName="p">{`Serverless becomes expensive at high loads`}</strong>{`. Where the balance tips depends on what you're doing, how much time you're saving, and how much it costs to do yourself.`}</p>
    <h3 {...{
      "id": "vendor-lock-in"
    }}>{`Vendor lock-in`}</h3>
    <p>{`This one's simple: You're building on somebody else's infrastructure. `}</p>
    <p>{`If that infrastructure changes, you're screwed. If they crank up the price, you're screwed. If you want to move, you're screwed. If you want to deploy your own, you're screwed.`}</p>
    <p>{`You `}<em parentName="p">{`can`}</em>{` do all those things, but it's a tedious and difficult task that might break your app. You're not building features or working on your business while you migrate.`}</p>
    <p>{`Startups rarely live long enough to have this problem. Enterprises take defensive measures like multi-year contracts with strict service level agreements. `}</p>
    <p><strong parentName="p">{`Avoid building architecture agnostic code.`}</strong>{` It's hard and you're not likely to need it.`}</p>
    <h3 {...{
      "id": "systems-complexity"
    }}>{`Systems complexity`}</h3>
    <p>{`You're paying for the simplicity of your application code with system complexity. Individual functions are simpler and easier to test. Complexity comes from how they interact.`}</p>
    <p>{`We'll talk more about that in the `}<a parentName="p" {...{
        "href": "/robust-backend-design"
      }}>{`Robust Backend Design`}</a>{` chapter.`}</p>
    <h2 {...{
      "id": "the-verdict"
    }}>{`The verdict?`}</h2>
    <p><video parentName="p" {...{
        "style": {
          "margin": "auto auto",
          "display": "block",
          "maxWidth": "80%"
        },
        "autoPlay": true,
        "loop": true,
        "muted": true,
        "playsInline": true,
        "loading": "lazy"
      }}>{`
            `}<source parentName="video" {...{
          "src": "https://media3.giphy.com/media/RLPQyPTQ3g4pKGD80m/giphy-loop.mp4?cid=4ac046a2nqdnn1e1e1knxrgfg5bknqwjqawkvpdgleh4o5ml&rid=giphy-loop.mp4&ct=g",
          "type": "video/mp4"
        }}></source>{`
        `}</video></p>
    <p>{`It depends. You will have to think about this yourself :)`}</p>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/swizec"
      }}>{`Ping me on twitter`}</a>{`, if you'd like some help.`}</p>
    <p>{`I like to use a series of questions:`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`"Will this require a lot of computation?`}</em>{` if the answer is yes, I consider building my own servers.`}</li>
      <li parentName="ul"><em parentName="li">{`"Will this have ridiculously high traffic?`}</em>{` if the answer is yes, I'd choose serverless because I hate doing DevOps. High traffic hopefully means I can afford a professional :)`}</li>
      <li parentName="ul"><em parentName="li">{`"Is this a small side project idea?"`}</em>{` serverless all the way`}</li>
      <li parentName="ul"><em parentName="li">{`"Does every request need to be served under 10ms?"`}</em>{` you're gonna have to roll your own`}</li>
    </ul>
    <p>{`Next chapter, we're going to talk about different serverless providers.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      